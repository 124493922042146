import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "assessment-content" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = {
  key: 1,
  class: "loading-gif"
}

import { computed, ref } from 'vue';
    import { useQuery } from '@vue/apollo-composable';
    import { useRoute } from 'vue-router';
    import { PATENT_QUERY } from '@/grapql/patentQuery';
    import { RESEARCH_PAPER_QUERY } from '@/grapql/researchPaperQuery';
    import { ASSET_TYPES } from '@/models/technologyAssetConstants';
    import { RESEARCH_PROJECT_QUERY } from '@/grapql/researchProjectQuery';
    import { OTHER_TECHNOLOGY_QUERY } from '@/grapql/otherTechnologyQuery';
    import { useStore } from 'vuex';
    import { useAssetDataDetailToMainContent } from '@/composables/useAssetDataDetailToMainContent';
    import { useAssetDataDetailToAssessmentContent } from '@/composables/useAssetDataDetailToAssessmentContent';
    import AssetDetailMainSection from '@/components/AssetDetailMainSection.vue';
    import AssetDetailAssessmentSection from '@/components/AssetDetailAssessmentSection.vue';
    import { ChevronDoubleRightIcon } from '@heroicons/vue/24/solid';
    import { AssetDetailMainDataType } from '@/models/technologyAssetInterface';
    import AssetTdsProcessModal from '@/components/AssetTdsProcessModal.vue';


    
export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetail',
  props: {id: { type: String }},
  setup(__props) {

    const route = useRoute();
    const store = useStore();
    const props = __props;
    const assetType = computed(() => props?.id.split('-').shift() ?? route.params.id.toString().split('-').shift());
    const assetID = computed(() => props?.id.split('-').pop() ?? route.params.id.toString().split('-').pop());
    const errorToDisplay = ref(null);
    const assetDetailMainData = ref<AssetDetailMainDataType>(null);
    const assetDetailAssessmentData = ref(null);
    const assessmentPannelOpen = computed(() => store.getters['assetDetailModule/getAssessmentPannelStatus']);
    
    const togglePannel = () => store.commit('assetDetailModule/toggleAssessmentPannel')

    const runQueryAndParseData = (QUERY, resultType:string) => {
        const {onResult , error} = useQuery(QUERY, {id: assetID}, {fetchPolicy: 'cache-and-network'});
        if (error) {
            errorToDisplay.value = error;
        }
        onResult(async queryResult => {
            if (queryResult.data && queryResult?.data[resultType]) {
                store.commit('assetDetailModule/resetValues');
                store.commit('assetDetailModule/setAssetValues', {data: queryResult.data[resultType], assetType: resultType});
                assetDetailMainData.value = await useAssetDataDetailToMainContent(queryResult.data[resultType]);
                assetDetailAssessmentData.value = await useAssetDataDetailToAssessmentContent(queryResult.data[resultType]);
            }
        })
    }

    const executeQuery =()=>{
        if (assetType.value === 'patent') {
            runQueryAndParseData(PATENT_QUERY, ASSET_TYPES.PATENT.graphqlValue);
        }
        if (assetType.value === 'research_paper') {
            runQueryAndParseData(RESEARCH_PAPER_QUERY, ASSET_TYPES.RESEARCH_PAPER.graphqlValue);
        }
        if (assetType.value === 'research_project') {
            runQueryAndParseData(RESEARCH_PROJECT_QUERY, ASSET_TYPES.RESEARCH_PROJECT.graphqlValue);
        }
        if (assetType.value === 'other') {
            runQueryAndParseData(OTHER_TECHNOLOGY_QUERY, ASSET_TYPES.OTHER.graphqlValue);
        }
    }

    // executeQuery for the first time on load
    executeQuery();
    
    
    // refresh data
    const handleTdsOnProcessed = () => {
        executeQuery();
    }

return (_ctx: any,_cache: any) => {
  return (assetDetailMainData.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["asset-detail-container", {'pannelClose': !assessmentPannelOpen.value}])
      }, [
        _createVNode(AssetTdsProcessModal, {
          "asset-id": assetID.value,
          onOnProcessed: handleTdsOnProcessed
        }, null, 8, ["asset-id"]),
        _createElementVNode("div", _hoisted_1, [
          (!assessmentPannelOpen.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "assessment-close",
                onClick: togglePannel
              }, [
                _createVNode(_unref(ChevronDoubleRightIcon), { class: "icon icon-small internal-pannel" }),
                _cache[0] || (_cache[0] = _createElementVNode("p", null, "Assessment", -1))
              ]))
            : (_openBlock(), _createBlock(AssetDetailAssessmentSection, {
                key: 1,
                assetDetailAssessmentData: assetDetailAssessmentData.value
              }, null, 8, ["assetDetailAssessmentData"]))
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AssetDetailMainSection, { assetDetailMainData: assetDetailMainData.value }, null, 8, ["assetDetailMainData"])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_3))
}
}

})