import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "form-container m-4 mt-0" }
const _hoisted_2 = { class: "add-asset-form mt-4" }
const _hoisted_3 = {
  key: 0,
  class: "one-five-orange text-white p-2 rounded mb-3"
}
const _hoisted_4 = { class: "flex items-start" }
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = { class: "overflow-y-auto" }
const _hoisted_7 = { class: "table-auto border border-slate-100 text-xs" }
const _hoisted_8 = { class: "p-2" }
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = { class: "w-full p-2 min-w-[250px]" }
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "w-full p-2 min-w-[150px]" }
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = ["value"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "w-full p-2 min-w-[150px]" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "w-full p-2 min-w-[150px]" }
const _hoisted_21 = ["onUpdate:modelValue"]
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "w-full p-2 min-w-[150px]" }
const _hoisted_24 = ["onUpdate:modelValue"]
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "w-full p-2" }
const _hoisted_27 = ["onUpdate:modelValue"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 0,
  class: "w-full"
}
const _hoisted_30 = { class: "table-auto border border-slate-100 text-xs" }
const _hoisted_31 = { class: "p-2" }
const _hoisted_32 = ["onUpdate:modelValue"]
const _hoisted_33 = { class: "w-full p-2" }
const _hoisted_34 = ["onUpdate:modelValue"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = {
  key: 0,
  class: "w-full"
}
const _hoisted_37 = { class: "table-auto border border-slate-100 text-xs" }
const _hoisted_38 = { class: "p-2" }
const _hoisted_39 = ["onUpdate:modelValue"]
const _hoisted_40 = { class: "w-full p-2" }
const _hoisted_41 = ["onUpdate:modelValue"]
const _hoisted_42 = { key: 1 }
const _hoisted_43 = {
  key: 0,
  class: "w-full"
}

import { computed } from 'vue';
import { useStore } from 'vuex';
import {ASSET_TDS_PROCESS_FIELDS} from '@/models/assetTdsFormFields';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';




export default /*@__PURE__*/_defineComponent({
  __name: 'AssetTdsProcessForm',
  props: ["valueTypes"],
  setup(__props) {

const store = useStore();

const props = __props;

const tds = computed(()=> store.getters['assetTdsModule/getTempTdsForProcess']);
const error = computed(()=>store.getters['assetTdsModule/getProcessError']);
const tdsOutputError = computed(()=> store.getters['assetTdsModule/getTempoTdsOutputError']);

// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => ASSET_TDS_PROCESS_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: ASSET_TDS_PROCESS_FIELDS.graphqlValue,
    mutation: 'assetTdsModule/setProcessSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        if(fieldName=="properties"){
            object[fieldName]=[];
            tds.value[fieldName].forEach((element,index) => {
                object[fieldName][index] = {}; // Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });

                Object.entries(element).forEach(([key,value])=>{
                    object[fieldName][index][key]= computed({
                            get() {
                                return store.state.assetTdsModule[formFieldOptions.base][fieldName][index][key];
                            },
                            set(value) {
                                store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: key , value: value});
                            }
                        })
                });
            });
        }else if(fieldName=="applications"){
            object[fieldName]=[];
            Object.entries(tds.value[fieldName]).forEach(([index,value])=>{
                object[fieldName][index] = {}; //Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });
                object[fieldName][index]['application'] = computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['application'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'application' , value: value});
                        }
                    });
            });
        }else if(fieldName=="certificates"){
            object[fieldName]=[];
            Object.entries(tds.value[fieldName]).forEach(([index,value])=>{
                object[fieldName][index] = {}; //Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });
                object[fieldName][index]['certificate'] = computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['certificate'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'certificate' , value: value});
                        }
                    });
                

            });
        }
    });
    return object;
}
const vModels = formFields(formFieldOptions)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(FormErrorToast, {
        "is-showing": !!error.value,
        title: "Warning!",
        subtitle: error.value,
        "toast-type": "warning"
      }, null, 8, ["is-showing", "subtitle"]),
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", null, [
          (tdsOutputError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, [
                  _createVNode(_unref(ExclamationTriangleIcon), { class: "icon icon-mid thick inline stroke-white" }),
                  _createElementVNode("span", _hoisted_5, [
                    _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Extraction Output Error: ", -1)),
                    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(tdsOutputError.value), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"bg-slate-100 p-2 rounded\"><strong>Note: </strong><br><ul class=\"list-disc ml-4\"><li class=\"mb-1\">Only selected records will be <strong>inserted</strong>, others will be skipped.</li><li class=\"mb--1\">If a <strong>PROPERTY</strong> with <strong>VALUE1</strong> already exists, the record will be skipped.</li></ul></div><div class=\"form-separator col-span-3\"><p class=\"form-subtitle\">Process Properties</p></div>", 2)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("table", _hoisted_7, [
              _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "text-left" }, [
                  _createElementVNode("th", { class: "pl-1" }, "ADD"),
                  _createElementVNode("th", { class: "p-2" }, "PROPERTY"),
                  _createElementVNode("th", { class: "p-2" }, "VALUE_TYPE"),
                  _createElementVNode("th", { class: "p-2" }, "VALU1"),
                  _createElementVNode("th", { class: "p-2" }, "VALUE2"),
                  _createElementVNode("th", { class: "p-2" }, "VALUE_UNIT"),
                  _createElementVNode("th", { class: "p-2" }, "EXTRA_INFORMATION")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vModels)['properties'], (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item,
                    class: _normalizeClass({ 'bg-slate-50 line-through select-none': item['selected'].value===false })
                  }, [
                    _createElementVNode("td", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": ($event: any) => ((item['selected'].value) = $event)
                      }, null, 8, _hoisted_9), [
                        [_vModelCheckbox, item['selected'].value]
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_10, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((item['quantitativeProperty'].value) = $event)
                          }, null, 8, _hoisted_11)), [
                            [_vModelText, item['quantitativeProperty'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item['quantitativeProperty'].value), 1))
                    ]),
                    _createElementVNode("td", _hoisted_13, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("select", {
                            key: 0,
                            class: "w-full",
                            "onUpdate:modelValue": ($event: any) => ((item['valueType'].value) = $event)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.valueTypes, (valueType) => {
                              return (_openBlock(), _createElementBlock("option", {
                                value: valueType.value,
                                key: valueType.key
                              }, _toDisplayString(valueType.value), 9, _hoisted_15))
                            }), 128))
                          ], 8, _hoisted_14)), [
                            [_vModelSelect, item['valueType'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(item['valueType'].value), 1))
                    ]),
                    _createElementVNode("td", _hoisted_17, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((item['value1'].value) = $event)
                          }, null, 8, _hoisted_18)), [
                            [_vModelText, item['value1'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(item['value1'].value), 1))
                    ]),
                    _createElementVNode("td", _hoisted_20, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((item['value2'].value) = $event)
                          }, null, 8, _hoisted_21)), [
                            [_vModelText, item['value2'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(item['value2'].value), 1))
                    ]),
                    _createElementVNode("td", _hoisted_23, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((item['valueUnit'].value) = $event)
                          }, null, 8, _hoisted_24)), [
                            [_vModelText, item['valueUnit'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(item['valueUnit'].value), 1))
                    ]),
                    _createElementVNode("td", _hoisted_26, [
                      (item['selected'].value!==false)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((item['extraInformation'].value) = $event)
                          }, null, 8, _hoisted_27)), [
                            [_vModelText, item['extraInformation'].value]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(item['extraInformation'].value), 1))
                    ])
                  ], 2))
                }), 128)),
                (_unref(vModels)['properties'].length==0)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_29, _cache[2] || (_cache[2] = [
                      _createElementVNode("td", {
                        colspan: "6",
                        class: "w-full p-2"
                      }, [
                        _createElementVNode("p", null, "No properties extracted")
                      ], -1),
                      _createElementVNode("td", null, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Process Applications")
          ], -1)),
          _createElementVNode("table", _hoisted_30, [
            _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "text-left" }, [
                _createElementVNode("th", { class: "pl-1" }, "ADD"),
                _createElementVNode("th", { class: "p-2" }, "APPLICATION")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vModels)['applications'], (item) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item,
                  class: _normalizeClass({ 'bg-slate-50 line-through select-none': item['selected'].value===false })
                }, [
                  _createElementVNode("td", _hoisted_31, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": ($event: any) => ((item['selected'].value) = $event)
                    }, null, 8, _hoisted_32), [
                      [_vModelCheckbox, item['selected'].value]
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_33, [
                    (item['selected'].value!==false)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          class: "w-full min-w-[250px]",
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((item['application'].value) = $event)
                        }, null, 8, _hoisted_34)), [
                          [_vModelText, item['application'].value]
                        ])
                      : (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(item['application'].value), 1))
                  ])
                ], 2))
              }), 128)),
              (_unref(vModels)['applications'].length==0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_36, _cache[4] || (_cache[4] = [
                    _createElementVNode("td", { class: "w-full p-2" }, [
                      _createElementVNode("p", null, "No applications extracted")
                    ], -1),
                    _createElementVNode("td", null, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Process Certificates")
          ], -1)),
          _createElementVNode("table", _hoisted_37, [
            _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "text-left" }, [
                _createElementVNode("th", { class: "pl-1" }, "ADD"),
                _createElementVNode("th", { class: "p-2" }, "CERTIFICATE")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vModels)['certificates'], (item) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item,
                  class: _normalizeClass({ 'bg-slate-50 line-through select-none': item['selected'].value===false })
                }, [
                  _createElementVNode("td", _hoisted_38, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": ($event: any) => ((item['selected'].value) = $event)
                    }, null, 8, _hoisted_39), [
                      [_vModelCheckbox, item['selected'].value]
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_40, [
                    (item['selected'].value!==false)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          class: "w-full min-w-[250px]",
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((item['certificate'].value) = $event)
                        }, null, 8, _hoisted_41)), [
                          [_vModelText, item['certificate'].value]
                        ])
                      : (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(item['certificate'].value), 1))
                  ])
                ], 2))
              }), 128)),
              (_unref(vModels)['certificates'].length==0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_43, _cache[6] || (_cache[6] = [
                    _createElementVNode("td", { class: "w-full p-2" }, [
                      _createElementVNode("p", null, "No certificates extracted")
                    ], -1),
                    _createElementVNode("td", null, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})