import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "list-tds text-center p-2 one-five-green text-white select-none"
}
const _hoisted_2 = {
  key: 1,
  class: "list-tds w-full text-center p-2 one-five-green text-white select-none"
}
const _hoisted_3 = { class: "one-five-green" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["onClick"]

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import LoaderPage from "./LoaderPage.vue";
import { useQuery } from '@vue/apollo-composable';
import {TDS_QUERY_WITH_DROPDOWNS} from "@/grapql/tdsQuery";
import AssetTdsProcessForm from "@/components/AssetTdsProcessForm.vue";
import {ASSET_TDS_PROCESS_FIELDS} from '@/models/assetTdsFormFields';
import {useAssetTdsToProcessFormData} from '@/composables/useAssetTdsToProcessFormData';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetTdsProcessModal',
  props: ["assetId"],
  emits: ["onProcessed"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emits = __emit;

const props = __props;
// hack, set and get id from store
store.commit("assetTdsModule/setAssetId",props.assetId);
const assetId = computed(()=> store.state.assetTdsModule.asset);

// set valuetypes for dropdown
const valueTypes = ref([])

const isOpen = ref(false);
const closeModal = (clean:boolean) => { 
    isOpen.value = false;
    if(clean===true){
        cleanUp();
    }
}
const openModal = () => {
    isOpen.value = true; 
}

const pollingInProgress = computed(()=> store.state.assetTdsModule.polling);
const processInProgress = computed(()=> store.state.assetTdsModule.processInProgress);

const getTdsesAndParse = () => {
    const {onResult, onError} = useQuery(TDS_QUERY_WITH_DROPDOWNS, {id: assetId});
    onError((error)=>{
        console.log(error);
    })
    onResult(async queryResult => {
        if (queryResult.data && queryResult?.data?.assetTempTdses) {
            store.commit('assetTdsModule/setTempTdsesValue', queryResult.data.assetTempTdses.edges.map(a=>a.node));
        }
        // set valuetypes for dropdown
        if(queryResult.data && queryResult?.data?.valueTypes){
            valueTypes.value = queryResult.data.valueTypes.edges.map(a=>(
                {key: a.node.name, value:a.node.name}
            ))
        }
    })
}

getTdsesAndParse();


const tempTdses = computed(()=>{
    return store.getters['assetTdsModule/getTempTdses'];
});

const tds = computed(()=> store.getters['assetTdsModule/getTempTdsForProcess']);


const handleTdsClick= (id)=>{
    // if tds is already set for process, just open the modal, else set tds for process
    if(tds.value){
        if(tds.value.id!=id){
            setTdsForProcess(id);
        }
    }
    
    openModal();
}

const setTdsForProcess=(id)=>{
    const filtered = tempTdses.value.filter(a=>a.id === id);
    const tempTds = filtered.length>0 ? filtered[0]:null;
    const output= useAssetTdsToProcessFormData(tempTds);
    const outputError = tempTds.error;
    store.commit('assetTdsModule/setTempTdsForProcess', {data:output, error:outputError});
}

const formSubmited = ref(false);

const handleProcess = async () =>{
    formSubmited.value = true;
    const mutation = ASSET_TDS_PROCESS_FIELDS.createMutation;
    await store.dispatch(mutation)
        .then(() => {
            formSubmited.value=false;
            closeModal(true);
            // emit onProcessed event
            emits('onProcessed');
        }).catch(()=>{
            console.log('error')
        });
}

async function cleanUp(){
    store.commit("assetTdsModule/cleanUpAfterProcess");
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (pollingInProgress.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " working on tds extraction . . . "))
      : _createCommentVNode("", true),
    (tempTdses.value && tempTdses.value.length>0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mr-4" }, "PROCESS TDS HERE", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tempTdses.value, (tds) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: tds.id,
                  class: "mr-2 p-2 pt-1 pb-1 bg-white text-black rounded cursor-pointer hover:shadow-md",
                  onClick: ($event: any) => (handleTdsClick(tds.id))
                }, "TDS " + _toDisplayString(tds.id), 9, _hoisted_5))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      dialogClass: "process-tds-modal",
      onCloseModal: closeModal
    }, {
      header: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("p", null, "Process Extracted Information", -1)
      ])),
      body: _withCtx(() => [
        (processInProgress.value)
          ? (_openBlock(), _createBlock(LoaderPage, {
              key: 0,
              "loader-text": "Submitting Form",
              "gif-position": "center"
            }))
          : _createCommentVNode("", true),
        _createVNode(AssetTdsProcessForm, { "value-types": valueTypes.value }, null, 8, ["value-types"])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "button cancel",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal(false)))
        }, "cancel"),
        _createElementVNode("button", {
          class: "button green-accept",
          onClick: handleProcess
        }, "Process")
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})