import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SingleFileUpload from '@/components/shared/SingleFileUpload.vue';
import { onBeforeMount, ref} from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetTdsFileUploadField',
  setup(__props) {

const store = useStore();
const fileAlreadySelected = ref(null)

const onFileToUpload = (file:File) => {
    store.commit('assetTdsModule/setFileToUpload', file);
}

onBeforeMount(() => {
    const storeSavedFile = store.getters['assetTdsModule/getFile'];
    if ( storeSavedFile ) {
        fileAlreadySelected.value = storeSavedFile
    }
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SingleFileUpload, {
    "label-text": "File",
    "preselected-file": fileAlreadySelected.value,
    onFileToUpload: onFileToUpload,
    "accept-extensions": ".pdf",
    "accept-text": "PDF"
  }, null, 8, ["preselected-file"]))
}
}

})