import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons flex" }
const _hoisted_4 = {
  key: 1,
  class: "round-action-icon"
}

import { ArrowUturnLeftIcon, FunnelIcon, PencilIcon, PlusIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TitleGoBackHeader',
  props: ['title', 'showFilter', 'showEdit', 'showAdd', 'changeButtonOnEdit'],
  emits: ['toogleFilter', 'toogleEdit'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit

const showEditingButton = ref(false);

const handleFilter = () => emit('toogleFilter');
const handleEdit = () => {
    if (props.changeButtonOnEdit) {
        showEditingButton.value = !showEditingButton.value;
    }
    emit('toogleEdit')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (__props.showFilter)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "round-action-icon",
            onClick: handleFilter
          }, [
            _createVNode(_unref(FunnelIcon), { class: "icon icon-small" })
          ]))
        : _createCommentVNode("", true),
      (__props.showAdd)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(PlusIcon), { class: "icon icon-small" })
          ]))
        : _createCommentVNode("", true),
      (__props.showEdit && !showEditingButton.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "round-action-icon",
            onClick: handleEdit
          }, [
            _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
          ]))
        : _createCommentVNode("", true),
      (showEditingButton.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "round-action-icon pink",
            onClick: handleEdit
          }, [
            _createVNode(_unref(CheckIcon), { class: "icon icon-small" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "round-action-icon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, [
        _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
      ])
    ])
  ]))
}
}

})