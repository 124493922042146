import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-container" }

import { computed, ref } from 'vue';
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import TechnologyAssetsComparisonCriteriaModal from '@/components/TechnologyAssetsComparisonCriteriaModal.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { GoodTablePageChangeInterface, GoodTablePerPageParamsInterface, GoodTableServerParamsInterface,  TechnologyAssetAsTableRowInterface } from '@/models/technologyAssetsInterface';
import { AssetSimpreInterface } from '@/models/assetsComparisonTableInterfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsTable',
  props: {
    assets: {},
    hasNextPage: { type: Boolean },
    totalRecords: {},
    isLoading: { type: Boolean },
    serverParams: {}
  },
  emits: ['onPageChange', 'onPerPageChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const router = useRouter();
const store = useStore();

const columns = computed(() => store.state.assetsModule.goodTableColumns);

const onCellClick = (params) => {
    if (params.column.field == 'url' && params.row.url) {
        const link = params.row.url.replace('<p>', '').replace('</p>', '') 
        window.open(link, '_blank')
    } else {
        const assetType = 
        params.row.assetType == 'Commercial & Other' ? 'other' 
        : params.row.assetType == 'External Opportunity' ? 'research_project'
        : params.row.assetType.replace(' ', '_').toLowerCase();
        
        store.commit('assetsModule/setTableServerOptions', {perPage: props.serverParams.perPage, page: props.serverParams.page})
        router.push({ name: 'TechnologyAssetDetail', params: {id: `${assetType}-${params.row.id}` }} )
    }
    
}

const onPageChange = (params: GoodTablePageChangeInterface) => emits('onPageChange', params);
const onOnPerPageChange = (params: GoodTablePerPageParamsInterface) => emits('onPerPageChange', params);

const selectedRows = ref<AssetSimpreInterface[]>([]);
const selectionChanged = (params: {selectedRows: TechnologyAssetAsTableRowInterface[]}) => selectedRows.value = params.selectedRows.map(({id, nickname, title}) => ({id, nickname, title}));
const compareAssets = () => {
    store.commit('assetsModule/setSelectedAssetForComparison', { assetsToCompare: selectedRows.value })
    store.commit('assetsModule/openComparisonCriteriaModal')
};


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueGoodTable), {
      mode: "remote",
      columns: columns.value,
      rows: _ctx.assets,
      styleClass: "vgt-table",
      "fixed-header": false,
      "select-options": { enabled: true, selectOnCheckboxOnly: true },
      "sort-options": { enabled: true, },
      onSelectedRowsChange: selectionChanged,
      onCellClick: onCellClick,
      totalRows: _ctx.totalRecords,
      "pagination-options": {
            enabled: true,
            perPage: _ctx.serverParams.perPage,
            setCurrentPage: _ctx.serverParams.page,
            perPageDropdown: [10, 20, 50],
            dropdownAllowAll: false,
        },
      onPageChange: onPageChange,
      onPerPageChange: onOnPerPageChange,
      isLoading: _ctx.isLoading
    }, {
      "selected-row-actions": _withCtx(() => [
        _createElementVNode("div", {
          class: "ml-3 pl-3 border-s flex items-center cursor-pointer",
          onClick: compareAssets
        }, [
          _createVNode(_unref(ClipboardDocumentCheckIcon), { class: "icon icon-small white thick mr-1" }),
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Compare", -1))
        ])
      ]),
      _: 1
    }, 8, ["columns", "rows", "totalRows", "pagination-options", "isLoading"]),
    _createVNode(TechnologyAssetsComparisonCriteriaModal)
  ], 512)), [
    [_vShow, _ctx.assets.length]
  ])
}
}

})