import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-container m-4 mt-0" }
const _hoisted_2 = { class: "add-asset-form mt-4" }
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-2.5 gap-x-4" }
const _hoisted_4 = ["value"]

import { ref, computed } from 'vue';
import { TableCellsIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import AssetTdsFileUploadField from './AssetTdsFileUploadField.vue';
import {ASSET_TDS_FIELDS} from '@/models/assetTdsFormFields';
import FormErrorToast from '@/components/FormErrorToast.vue';
import LoaderPage from "./LoaderPage.vue";
import { useQuery } from '@vue/apollo-composable';
import {TDS_QUERY} from "@/grapql/tdsQuery";
import RadioGroup from "@/components/shared/RadioGroup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetTdsModal',
  props: ["assetPdfFiles"],
  setup(__props) {

const props = __props;

const store = useStore();

const assetId = computed(()=>store.state.assetTdsModule.asset );
const pollingInProgress = computed(()=>store.state.assetTdsModule.polling);

const isOpen = ref(false);
const closeModal = () => { isOpen.value = false;}
const openModal = () => {
    if(!pollingInProgress.value){
        isOpen.value = true; 
    }
}

const newUpload = [
  { key: false, value: "I want to select from list" },
  { key: true, value: "I want to upload new tds"},
];

const errorsDuringMutation = computed(() => store.state.assetTdsModule.extractError);
const extractInProgress = computed(()=> store.state.assetTdsModule.extractInProgress);


// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => ASSET_TDS_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: ASSET_TDS_FIELDS.graphqlValue,
    mutation: 'assetTdsModule/setSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetTdsModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    })
    return object;
}
const vModels = formFields(formFieldOptions);

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.assetTdsModule[ASSET_TDS_FIELDS.graphqlValue]);
const formIsValid = computed(() => ASSET_TDS_FIELDS.mandatoryFormFields.every(mf => {
    if(mf=="assetFile" &&  !!valuesSubmitted.value['isNewUpload']==true){
        return true;
    }
    else if (mf=='file' && !!valuesSubmitted.value['isNewUpload']==false){
        return true;
    }
    else if(mf=="isNewUpload" && (valuesSubmitted.value['isNewUpload']==false || valuesSubmitted.value['isNewUpload']==true)){
        return true;
    }
    return !!valuesSubmitted.value[mf]
}));
const errorFields = computed(() => ASSET_TDS_FIELDS.mandatoryFormFields.filter(mf => {
    if(mf=="assetFile"){
        return !!valuesSubmitted.value['isNewUpload']==true ? false: !valuesSubmitted.value[mf];
    }
    else if (mf=='file'){
        return !!valuesSubmitted.value['isNewUpload']==true ? !valuesSubmitted.value[mf]: false;
    }
    else if(mf=="isNewUpload"){
        return valuesSubmitted.value['isNewUpload']==true || valuesSubmitted.value['isNewUpload']==false ? false : true;
    }
    return !valuesSubmitted.value[mf]
}) );


const handleExtract = async () =>{
    formSubmited.value = true;
    if (formIsValid.value) {
        const query = ASSET_TDS_FIELDS.query;
        await store.dispatch(query)
        .then(()=>{
            formSubmited.value = false;
            closeModal();
            store.commit("assetTdsModule/resetStore");

            // start polling with 1 second interval until you get result,
            getTdsandParseByPolling();
        })
        .catch((error)=>{
            console.log('Catched Error: '+error)
        });
}

}


const getTdsandParseByPolling= () =>{
    // set polling  is progress to true
    store.commit('assetTdsModule/setPolling', true);
    const {onResult, onError, stop} = useQuery(TDS_QUERY, {id: assetId.value},{pollInterval:3500, fetchPolicy: 'no-cache'});
    onError((error)=>{
        console.log(error);
        stop();
        // set polling  is progress to false
        store.commit('assetTdsModule/setPolling', false);
    })
    onResult(async queryResult => {
        if (queryResult.data && queryResult?.data?.assetTempTdses) {
            // if there is data, so stop polling
            if(queryResult.data.assetTempTdses.edges.length > 0){
                stop();
                // set polling  is progress to to false
                store.commit('assetTdsModule/setPolling', false);
                store.commit('assetTdsModule/setTempTdsesValue', queryResult.data.assetTempTdses.edges.map(a=>a.node));
                
            }
        }
    })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["edit-tds select-none", {active: isOpen.value}]),
      onClick: openModal,
      style: {"right":"120px"},
      title: "Technical Datasheet Extraction"
    }, [
      _createVNode(_unref(TableCellsIcon), { class: "icon" }),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "TDS", -1))
    ], 2),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      dialogClass: "extract-tds-modal",
      onCloseModal: closeModal
    }, {
      header: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("p", null, "Upload or select Technical Datasheet for Extraction", -1)
      ])),
      body: _withCtx(() => [
        (extractInProgress.value)
          ? (_openBlock(), _createBlock(LoaderPage, {
              key: 0,
              "loader-text": "Submitting Form",
              "gif-position": "center"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(FormErrorToast, {
            "is-showing": !!errorsDuringMutation.value,
            title: "Warning!",
            subtitle: errorsDuringMutation.value,
            "toast-type": "warning"
          }, null, 8, ["is-showing", "subtitle"]),
          _createVNode(FormErrorToast, {
            "is-showing": formSubmited.value && !formIsValid.value,
            title: "The following fields are mandatory:",
            subtitle: errorFields.value.join(', ')
          }, null, 8, ["is-showing", "subtitle"]),
          _createElementVNode("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(RadioGroup, {
                class: _normalizeClass(["col-span-3 mb-0 select-none", { 'error-form-field': errorFields.value.includes('isNewUpload') && formSubmited.value }]),
                modelValue: _unref(vModels)['isNewUpload'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['isNewUpload'].value) = $event)),
                options: newUpload,
                label: 'Do you want to select an already uploaded TDS file or upload a new TDS?'
              }, null, 8, ["modelValue", "class"]),
              (!_unref(vModels)['isNewUpload'].value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["col-span-3", { 'error-form-field': errorFields.value.includes('assetFile') && formSubmited.value }])
                  }, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", null, "Select Technical Datasheet Pdf File *", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "w-full",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(vModels)['assetFile'].value) = $event))
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("option", {
                        selected: "",
                        value: null
                      }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.assetPdfFiles, (status) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: Number(status.id),
                          key: status.id
                        }, _toDisplayString(status.filename), 9, _hoisted_4))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, _unref(vModels)['assetFile'].value]
                    ])
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["col-span-3 mt-2", { 'error-form-field': errorFields.value.includes('file') && formSubmited.value }])
                  }, [
                    _createVNode(AssetTdsFileUploadField)
                  ], 2))
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "button cancel",
          onClick: closeModal
        }, "cancel"),
        _createElementVNode("button", {
          class: "button green-accept",
          onClick: handleExtract
        }, "Extract")
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})