<template>
    <div class="title-menu-header">
        <h1 class="view-title">{{ title }}</h1>
        <div class="action-icons flex">
            <div v-if="showFilter" class="round-action-icon" @click="handleFilter">
                <FunnelIcon class="icon icon-small" />
            </div>
            <div v-if="showAdd" class="round-action-icon">
                <PlusIcon class="icon icon-small" />
            </div>
            <div v-if="showEdit && !showEditingButton" class="round-action-icon" @click="handleEdit">
                <PencilIcon class="icon icon-small" />
            </div>
            <div v-if="showEditingButton" class="round-action-icon pink" @click="handleEdit">
                <CheckIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="$router.go(-1)">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, FunnelIcon, PencilIcon, PlusIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps(['title', 'showFilter', 'showEdit', 'showAdd', 'changeButtonOnEdit']);
const emit = defineEmits(['toogleFilter', 'toogleEdit'])

const showEditingButton = ref(false);

const handleFilter = () => emit('toogleFilter');
const handleEdit = () => {
    if (props.changeButtonOnEdit) {
        showEditingButton.value = !showEditingButton.value;
    }
    emit('toogleEdit')
}
</script>
