import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-padding" }

import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import BenchmarkTable from '@/components/BenchmarkTable.vue';
import {ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BenchmarkView',
  setup(__props) {

const editOn = ref(false)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TitleGoBackHeader, {
      title: "Benchmark",
      "show-edit": true,
      "change-button-on-edit": true,
      onToogleEdit: _cache[0] || (_cache[0] = ($event: any) => (editOn.value = !editOn.value))
    }),
    _createVNode(BenchmarkTable, { editOn: editOn.value }, null, 8, ["editOn"])
  ]))
}
}

})