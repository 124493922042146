import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_2 = { class: "flex w-full items-center justify-between" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "shrink-0 text-white items-right" }


import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioGroup',
  props: {
    options: {},
    label: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RadioGroup), { class: "col-span-3 mb-0 select-none" }, {
    default: _withCtx(() => [
      _createVNode(_unref(RadioGroupLabel), { class: "mb-3" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "label")
        ]),
        _: 3
      }),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
          return (_openBlock(), _createBlock(_unref(RadioGroupOption), {
            as: "template",
            key: item.key,
            value: item.key
          }, {
            default: _withCtx(({ active, checked }) => [
              _createElementVNode("div", {
                class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_unref(RadioGroupLabel), {
                        as: "p",
                        class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ])
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_5, _cache[0] || (_cache[0] = [
                    _createElementVNode("svg", {
                      class: "h-5 w-5",
                      viewBox: "0 0 24 24",
                      fill: "none"
                    }, [
                      _createElementVNode("circle", {
                        cx: "12",
                        cy: "12",
                        r: "12",
                        fill: "#fff",
                        "fill-opacity": "0.2"
                      }),
                      _createElementVNode("path", {
                        d: "M7 13l3 3 7-7",
                        stroke: "#fff",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ], -1)
                  ]), 512), [
                    [_vShow, checked]
                  ])
                ])
              ], 2)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ])
    ]),
    _: 3
  }))
}
}

})