import { BenchamarkValue, BenchmarkPropertiesInterface, BenchmarkSolutionsInterface } from "@/models/benchmarkModels"
import { ExternalSolutionInterface, HasuraDesignsInterface } from "@/models/hasuraModels"
import { TargetInterface } from "@/models/programVersionsInterface"
import { ref } from "vue"


const getValues = (criteria: BenchmarkPropertiesInterface[], solution:ExternalSolutionInterface | HasuraDesignsInterface) => {
    const newValues = []
    criteria.map(criteriaValue => {
        if (criteriaValue.criteria == 'OTR') {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: solution.min_wvtr_23_50 ? Number(solution.min_wvtr_23_50).toFixed(1) : solution.min_wvtr_38_90 ? Number(solution.min_otr_23_50).toFixed(1) : null,
                conditions: solution.min_otr_23_0 ? 'T: 23°C - RH: 0%' : solution.min_otr_23_50 ? 'T: 23°C - RH: 50%' : null,
                value2:  solution.min_otr_23_0 && solution.min_otr_23_50 ? Number(solution.min_otr_23_0).toFixed(1) : null,
                conditions2: solution.min_otr_23_0 && solution.min_otr_23_50 ? 'T: 23°C - RH: 50%' : null, 
            }
            newValues.push(newValue)
        }
        else if (criteriaValue.criteria == 'WVTR') {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: solution.min_wvtr_23_50 ? Number(solution.min_wvtr_23_50).toFixed(1) : solution.min_wvtr_38_90 ? Number(solution.min_wvtr_38_90).toFixed(1) : null,
                conditions: solution.min_wvtr_23_50 ? 'T: 23°C - RH: 0%' : solution.min_wvtr_38_90 ? 'T: 23°C - RH: 50%' : null,
                value2:  solution.min_wvtr_23_50 && solution.min_wvtr_38_90 ? Number(solution.min_wvtr_23_50).toFixed(1) : null,
                conditions2: solution.min_wvtr_23_50 && solution.min_wvtr_38_90 ? 'T: 23°C - RH: 50%' : null, 
            }
            newValues.push(newValue)
        }
        else if (criteriaValue.criteria == 'Paper content (%)') {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: solution.paper_content == "NaN" ? null : solution.paper_content
            }
            newValues.push(newValue)
        }
        else if (criteriaValue.criteria == 'Seal strength') {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: solution.hs_max_tech_reps ? Number(solution.hs_max_tech_reps).toFixed(1) : null 
            }
            newValues.push(newValue)
        }
        else if (criteriaValue.criteria == 'Maximum total weight (gsm)') {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: solution.total_grammage == "NaN" ? null : Number(solution.total_grammage).toFixed(1)
            }
            newValues.push(newValue)
        }
        else {
            const newValue:BenchamarkValue = {
                criteria: criteriaValue,
                value: null
            }
            newValues.push(newValue)
        }
    })
    return newValues

}

export const createBenchmarkForExternalSolutions = (criteria: BenchmarkPropertiesInterface[], externalPackages: ExternalSolutionInterface[] ) => {
    const externalWithBenchmarkValues = [];
    externalPackages.map((extPackage:ExternalSolutionInterface) => {
        const newObject:BenchmarkSolutionsInterface  = {
            description: extPackage.description,
            name: extPackage.design_name,
            values: getValues(criteria, extPackage)
        }
        externalWithBenchmarkValues.push(newObject);
        
    });
    return externalWithBenchmarkValues
}

export const createBenchmarkForInternalSolutions = (criteria: BenchmarkPropertiesInterface[], internalPackages: HasuraDesignsInterface[] ) => {
    const internalWithBenchmarkValues = [];
    internalPackages.map((intPackage:HasuraDesignsInterface) => {
        const newObject:BenchmarkSolutionsInterface  = {
            description: intPackage.description,
            name: intPackage.pro_mat_name,
            values: getValues(criteria, intPackage)
        }
        internalWithBenchmarkValues.push(newObject);
        
    });
    return internalWithBenchmarkValues
}

const parseTargetValue = (targetValue:TargetInterface ) => {
    if (targetValue.targetValueType.name == 'Between') {
        return targetValue.targetValueUnit ? `${targetValue.targetValue1} - ${targetValue.targetValue2} ${targetValue.targetValueUnit.abbreviation}` : `${targetValue.targetValue1} - ${targetValue.targetValue2}`
    }
    if (targetValue.targetValueType.name == 'Boolean') {
        return targetValue.targetValue1 == 'true' ? 'Yes' : 'No'
    }
    else {
        return targetValue.targetValueUnit ? `${targetValue.targetValueType.symbol} ${targetValue.targetValue1} ${targetValue.targetValueUnit.abbreviation}` : `${targetValue.targetValueType.symbol} ${targetValue.targetValue1}`;
    }
}

const getValuesForTargets = (criteriaValue: BenchmarkPropertiesInterface, targetValues: TargetInterface[]) => {
    if (criteriaValue.criteria == 'OTR') {
        const mappedTarget = targetValues.find(targetValue => targetValue.targetProperty.category == 'oxygen-barrier')
        const newValue:BenchamarkValue = {
            criteria: criteriaValue,
            value: mappedTarget ? parseTargetValue(mappedTarget) : null,
            originalValue: mappedTarget ? mappedTarget.targetValue1 : null,
            comparator: mappedTarget ? mappedTarget.targetValueType.symbol : null
        }
        return newValue
    }
    if (criteriaValue.criteria == 'WVTR') {
        const mappedTarget = targetValues.find(targetValue => targetValue.targetProperty.category == 'water-vapour-barrier')
        const newValue:BenchamarkValue = {
            criteria: criteriaValue,
            value: mappedTarget ? parseTargetValue(mappedTarget) : null,
            originalValue: mappedTarget ? mappedTarget.targetValue1 : null,
            comparator: mappedTarget ? mappedTarget.targetValueType.symbol : null
        }
        return newValue
    }
    if (criteriaValue.criteria == 'Paper content (%)' || criteriaValue.criteria == 'Seal strength' || criteriaValue.criteria == 'Maximum total weight (gsm)') {
        const mappedTarget = targetValues.find(targetValue => targetValue.targetProperty.name == criteriaValue.criteria)
        const newValue:BenchamarkValue = {
            criteria: criteriaValue,
            value: mappedTarget ? parseTargetValue(mappedTarget) : null,
            originalValue: mappedTarget ? mappedTarget.targetValue1 : null,
            comparator: mappedTarget ? mappedTarget.targetValueType.symbol : null
        }
        return newValue
    }
    else {
        const newValue:BenchamarkValue = {
            criteria: criteriaValue,
            value: null,
            originalValue: null,
            comparator: null
        }
        return newValue
    }
    
}

export const createBenchmarkForTargetValues = (criteria: BenchmarkPropertiesInterface[], targetValues: TargetInterface[]) => {
    const valuesFromTargets = [];
    criteria.map((criteriaValue:BenchmarkPropertiesInterface) => {
        const value = getValuesForTargets(criteriaValue, targetValues);
        valuesFromTargets.push(value)
    });
    const newObject:BenchmarkSolutionsInterface  = {
        description: '',
        name: 'Target Values',
        values: valuesFromTargets
    }
    return newObject
    
}

