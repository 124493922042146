import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["accept"]
const _hoisted_2 = {
  class: "file-label",
  for: "uploadFile"
}
const _hoisted_3 = { class: "input-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "file-list-container" }
const _hoisted_10 = { class: "upload-file-preview" }
const _hoisted_11 = { class: "card-content" }
const _hoisted_12 = { class: "break-all" }
const _hoisted_13 = { class: "file-size" }

import { ref, onMounted } from 'vue';
import { CloudArrowUpIcon, XMarkIcon, DocumentPlusIcon } from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleFileUpload',
  props: ['labelText', 'preselectedFile','acceptExtensions','acceptText'],
  emits: ['fileToUpload'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit;

const isDragging = ref(false);
const fileToUpload = ref();

const singleFileUploadInput = ref(null);

const getFile = (e) => {
    if(e.target.files.length>0){
        fileToUpload.value = e.target.files[0];
        emit('fileToUpload', fileToUpload.value)
    }
};
const dragover = (e:Event) => {
    e.preventDefault();
    isDragging.value = true;
}
const dragleave = () => isDragging.value = false;
const drop = (e) => {
    e.preventDefault();
    fileToUpload.value = e.dataTransfer.files[0];
    isDragging.value = false;
    emit('fileToUpload', fileToUpload.value)
}
const removeFile = () => {
    fileToUpload.value = null
    emit('fileToUpload', null);
    singleFileUploadInput.value.value = '';
}

onMounted(() => {
    if (props.preselectedFile) { fileToUpload.value = props.preselectedFile; }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "dropzone-container",
      onDragover: dragover,
      onDragleave: dragleave,
      onDrop: drop
    }, [
      _createElementVNode("input", {
        ref_key: "singleFileUploadInput",
        ref: singleFileUploadInput,
        type: "file",
        name: "uploadFile",
        class: "hidden-input",
        id: "uploadFile",
        onChange: getFile,
        accept: __props.acceptExtensions ?? '.pdf,.txt,.csv,.doc,.docx,.ppt,.pptx,.xsl,.xlsx,.png,.jpg,.jpeg,.gif,.bmp,.webp'
      }, null, 40, _hoisted_1),
      _createElementVNode("label", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(CloudArrowUpIcon), { class: "icon icon-small icon-upload" }),
          (isDragging.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Release to drop file here."))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[1] || (_cache[1] = _createElementVNode("p", null, [
                  _createTextVNode("Drop file here or "),
                  _createElementVNode("u", null, "click here"),
                  _createTextVNode(" to upload.")
                ], -1)),
                _createElementVNode("span", null, _toDisplayString(__props.acceptText ?? "PDF, TXT, CSV, DOC, PPT, XLS, PNG, JPG or GIF"), 1)
              ]))
        ])
      ])
    ], 32),
    (fileToUpload.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (__props.labelText)
            ? (_openBlock(), _createElementBlock("label", _hoisted_7, _toDisplayString(__props.labelText), 1))
            : (_openBlock(), _createElementBlock("label", _hoisted_8, "Selected File")),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_unref(DocumentPlusIcon), { class: "icon thick icon-file add" }),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(fileToUpload.value.name), 1),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(Math.round(fileToUpload.value.size / 1000) + "kb"), 1)
                ])
              ]),
              _createVNode(_unref(XMarkIcon), {
                class: "icon icon-small shrink-0",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (removeFile()))
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})