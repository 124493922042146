import { ExternalSolutionInterface, HasuraDesignsInterface } from "./hasuraModels";

export interface BenchmarkPropertiesInterface {
    criteria: string, 
    category?: string, 
    ready?: boolean
}

export const BENCHMARK_PROPERTIES:BenchmarkPropertiesInterface[] = [
    {
        criteria: 'OTR',
        category: 'Barrier properties',
        ready: true
    },
    {
        criteria: 'WVTR',
        category: 'Barrier properties',
        ready: true,
    },
    {
        criteria: 'Paper content (%)',
        category: 'Compliance properties',
        ready: true
    },
    {
        criteria: 'Grease Permeability',
        category: 'Barrier properties',
        ready: false,
    },
    {
        criteria: 'MOAH barrier',
        category: 'Barrier properties',
        ready: false,
    },
    {
        criteria: 'Seal strength',
        category: 'Sealing properties',
        ready: true,
    },
    {
        criteria: 'Desired EoL',
        ready: false,
    },
    {
        criteria: 'Maximum total weight (gsm)',
        category: 'Mass properties',
        ready: true,
    },
    {
        criteria: 'Extended shelf life',
        ready: false
    },
    {
        criteria: 'Thickness',
        ready: false
    },
    {
        criteria: 'CoF',
        category: 'Machinability parameters',
        ready: false
    },
    {
        criteria: 'Stiffness',
        category: 'Machinability parameters',
        ready: false 
    },
    {
        criteria: 'Tensile strength',
        category: 'Machinability parameters', 
        ready: false
    },
    {
        criteria: 'Form required? Preformed or reel',
        ready: false
    }
]

export const BENCHMARK_COMMERCIAL_PROPERTIES = [
    {
        criteria: 'Price target',
        category: 'Commercial Value',
    },
    {
        criteria: 'Annual volume',
        category: 'Commercial Value',
    },
    {
        criteria: 'MOQs and lead time',
        category: 'Commercial Value',
    },
    {
        criteria: 'Markets of focus',
        category: 'Commercial Value',
    },
    {
        criteria: 'Timeline',
        category: 'Commercial Value',
    },
    {
        criteria: 'Licence / offtake preferred',
        category: 'Commercial Value',
    },
];

export interface StoreBenchmarkData {
    selectedProperties: BenchmarkPropertiesInterface[], 
    selectedCommercialValues: BenchmarkPropertiesInterface[], 
    ourPackages: HasuraDesignsInterface[], 
    externalPackages: ExternalSolutionInterface[]
}

export interface BenchmarkSolutionsInterface {
    name: string,
    description: string, 
    values: BenchamarkValue[] 
 }
 
 export interface BenchamarkValue {
    criteria: BenchmarkPropertiesInterface, 
    value: string | null,
    conditions?: string, 
    value2?: string | null,
    conditions2?: string | null,
    originalValue?: string | null,
    comparator?: string | null,
    colour?: string | null,
 }
 
 