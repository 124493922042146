<template>
    <div class="page-padding">
        <TitleGoBackHeader title="Benchmark" :show-edit="true" :change-button-on-edit="true" @toogle-edit="editOn = !editOn" />
        <BenchmarkTable :editOn="editOn"/>
    </div>
</template>
<script setup lang="ts">
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import BenchmarkTable from '@/components/BenchmarkTable.vue';
import {ref} from 'vue';

const editOn = ref(false)

</script>