<template>
    <template>
	<ModalSlots :isOpen="isOpen" dialogClass="m-modal" @close-modal="$emit('closeModal')" @click-create="handleCreateBenchmarkTable" :create-in-progress="disableButton">
		<template v-slot:header>
			<p>Benchmarking</p>
		</template>
		<template v-slot:body>
			<div class="px-6 pb-12">
                <!-- PROPERTIES SELECTION -->
                <div class="form-separator"><p class="form-subtitle">properties</p></div>
                <div class="grid grid-cols-2 gap-4">
                    <div class="filter-values flex flex-col">
                        <template v-for="property in benchmarkProperties" :key="property.criteria" >
                            <label class="filter-option justify-between">
                                <div class="flex flex-col leading-4">
                                    <p class="text-sm uppercase font-medium text-black">{{ property.criteria }}</p>
                                    <p class="text-xs text-neutral-400">{{ property.category }}</p>
                                </div>
                                <input type="checkbox" :id="property.criteria" :value="property" v-model="selectedProperties">
                            </label>
                        </template>
                    </div>
                    <div class="filter-values flex flex-col">
                        <template v-for="commercialValue in commercialProperties" :key="commercialValue">
                            <label class="filter-option justify-between">
                                <div class="flex flex-col leading-4">
                                    <p class="text-sm uppercase font-medium text-black">{{ commercialValue.criteria }}</p>
                                    <p class="text-xs text-neutral-400">{{commercialValue.category}}</p>
                                </div>
                                <input type="checkbox" :id="commercialValue.criteria" :value="commercialValue" v-model="selectedCommercial">
                            </label>
                        </template>
                    </div>
                </div>
                <!-- OUR DESIGNS SELECTION -->
                <div class="form-separator"><p class="form-subtitle">our packaging</p></div>
                <div class="filter-values flex flex-wrap"> 
                    <template v-for="design in ourPackages" :key="design.description">
                        <label class="filter-option hidden-check mr-4" :class="{'border-on': selectedOwnPackages.includes(design)}">
                            <img src="../assets/icons/layers.svg" class="w-2/3 max-w-12 pr-4 pl-1" :class="[!selectedOwnPackages.includes(design) ? 'opacity-50' : 'opacity-100']"/>
                            <div class="flex flex-col leading-4">
                                <p class="text-sm uppercase font-medium text-black">{{ design.pro_mat_name }}</p>
                                <p class="text-xs text-neutral-400">{{design.description}}</p>
                            </div>
                            <input type="checkbox" :id="design.description" :value="design" v-model="selectedOwnPackages">
                        </label>
                    </template>
                </div>
                 <!-- EXTERNAL DESIGNS SELECTION -->
                 <div class="form-separator"><p class="form-subtitle">external packaging</p></div>
                <div class="filter-values flex-wrap grid grid-cols-2"> 
                    <template v-for="ext in externalPackages" :key="ext.description">
                        <label class="filter-option hidden-check mr-4" :class="{'border-on': selectedExternalPackages.includes(ext)}">
                            <img src="../assets/icons/food-delivery.svg" class="w-2/3 max-w-12 pr-4 pl-1" :class="[!selectedExternalPackages.includes(ext) ? 'opacity-50' : 'opacity-100']"/>
                            <div class="flex flex-col leading-4">
                                <p class="text-sm uppercase font-medium text-black">{{ ext.design_name }}</p>
                                <p class="text-xs text-neutral-400">{{ext.description}}</p>
                            </div>
                            <input type="checkbox" :id="ext.description" :value="ext" v-model="selectedExternalPackages">
                        </label>
                    </template>
                </div>
            </div>
		</template>
	</ModalSlots>
</template>  
</template>
<script setup lang="ts">
import ModalSlots from "@/components/ModalSlots.vue";
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue';
import {BENCHMARK_PROPERTIES, BENCHMARK_COMMERCIAL_PROPERTIES, BenchmarkPropertiesInterface} from '@/models/benchmarkModels';
import { useStore } from 'vuex';
import { ExternalSolutionInterface, HasuraDesignsInterface } from "@/models/hasuraModels";
import { EXT_SOLUTIONS } from "@/grapql/hasuraQueries";
import { useQuery } from '@vue/apollo-composable';
import router from '@/router';

defineProps<{isOpen: boolean}>();
defineEmits(['closeModal']);

const store = useStore();

// Properties
const benchmarkProperties = computed<BenchmarkPropertiesInterface[]>(() => BENCHMARK_PROPERTIES.filter(property => property.ready));
const commercialProperties = ref<BenchmarkPropertiesInterface[]>(BENCHMARK_COMMERCIAL_PROPERTIES);
const selectedProperties = ref([]);
const selectedCommercial = ref([]);

// Benchling packages - our packages
const allPackages = computed(() => store.getters['programVersionDetailModule/getDesignsFromBenchlig']);
const programName = computed(() => store.getters['programVersionDetailModule/getProgramName']);
const designsToShow = computed<HasuraDesignsInterface[]>(() => allPackages.value ? allPackages.value.filter((design: HasuraDesignsInterface) => design.programmes.includes(programName.value)) : null);
const ourPackages = computed<HasuraDesignsInterface[]>(() => designsToShow.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'Yes') : null);
const selectedOwnPackages = ref<HasuraDesignsInterface[]>([]);

// External entities from Benchling - External packages
const externalPackages = ref<ExternalSolutionInterface[]>([]);
const selectedExternalPackages = ref([])
const options = ref({clientId: 'hasuraClient'});

const { onResult } = useQuery(EXT_SOLUTIONS, null, options);
onResult(queryResult => {
    if(queryResult.data && queryResult.data.prod_mart_data_external_designs) {
        externalPackages.value = queryResult.data.prod_mart_data_external_designs;
    }
    
})

const disableButton = computed(() => selectedOwnPackages.value.length == 0 && selectedExternalPackages.value.length == 0)
const handleCreateBenchmarkTable = () => {
    const payload = {
        selectedProperties: selectedProperties.value, 
        selectedCommercialValues: selectedCommercial.value, 
        ourPackages: selectedOwnPackages.value, 
        externalPackages: selectedExternalPackages.value
    }
    store.commit('programVersionDetailModule/saveValuesForBenchmarking', payload )
    router.push({name: 'Benchmark'});
}

onMounted(() => {
    selectedProperties.value = benchmarkProperties.value;
    selectedCommercial.value = commercialProperties.value; 
})



</script>
<style lang="scss" scoped>
.filter-values {
    //display: flex;
    .filter-option {
        margin-bottom: 0.8rem;
        font-size: 1rem;
        display: flex;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 5px 10px;
        align-items: center;
        // justify-content: space-between;
        cursor: pointer;
        input {
            width: 16px;
            height: 16px;
            border-radius: 20px;
            margin-right: 0.5rem;
            margin-top: 0.3rem;
        }
        &.hidden-check {
            &.border-on {
                border: 2px solid black;
                background-color: #feedf4;
            }
            input {
                appearance: none;
                border: none;
                &:checked {
                    background-image: none;
                }
            }
        }
    }
}
    
</style>