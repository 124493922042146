import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex table-container" }
const _hoisted_2 = { class: "flex flex-col sticky left-0 z-20 border-2 border-r-0 border-black" }
const _hoisted_3 = { class: "uppercase text-sm" }
const _hoisted_4 = { class: "text-xs text-zinc-400 font-light capitalize" }
const _hoisted_5 = { class: "flex sticky left-72 z-20" }
const _hoisted_6 = { class: "flex flex-col w-56 border-2 border-black bg-pink divide-y divide-slate-200" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex border-2 border-l-0 border-black" }
const _hoisted_11 = { class: "flex flex-col p-2 h-24 border-r bg-white justify-center items-center" }
const _hoisted_12 = { class: "uppercase text-sm text-center" }
const _hoisted_13 = { class: "text-xs text-zinc-400 font-light capitalize text-center break-all" }
const _hoisted_14 = { class: "flex border-2 border-l-0 border-black" }
const _hoisted_15 = { class: "flex flex-col p-2 h-24 border-r bg-white justify-center items-center" }
const _hoisted_16 = { class: "uppercase text-sm text-center" }
const _hoisted_17 = { class: "text-xs text-zinc-400 font-light capitalize text-center break-all" }

import { useStore } from 'vuex';
import {computed} from 'vue';
import { StoreBenchmarkData, BenchmarkSolutionsInterface } from '@/models/benchmarkModels';
import { createBenchmarkForExternalSolutions, createBenchmarkForInternalSolutions, createBenchmarkForTargetValues } from '@/composables/useDataForBenchmark'
import { TargetInterface } from '@/models/programVersionsInterface';
import BenchmarkTableCel from '@/components/BenchmarkTableCel.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BenchmarkTable',
  props: {
    editOn: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const store = useStore();



const targetValues = computed<TargetInterface[]>(() => store.getters['programVersionDetailModule/getprogramVersionTargets']);
const targetValuesForBenchmark = computed<BenchmarkSolutionsInterface>(() => targetValues.value && benchmarkCriteria.value ? createBenchmarkForTargetValues(benchmarkCriteria.value, targetValues.value) : null)
const benchmarkData = computed<StoreBenchmarkData>(() => store.getters['programVersionDetailModule/getBenchmarkData']);
const benchmarkCriteria = computed(() => [...benchmarkData.value.selectedProperties, ...benchmarkData.value.selectedCommercialValues]);
const externalPackages = computed<BenchmarkSolutionsInterface[]>(() => benchmarkData.value && benchmarkCriteria.value ? createBenchmarkForExternalSolutions(benchmarkCriteria.value, benchmarkData.value.externalPackages ) : [])
const internalPackages = computed<BenchmarkSolutionsInterface[]>(() => benchmarkData.value && benchmarkCriteria.value ? createBenchmarkForInternalSolutions(benchmarkCriteria.value, benchmarkData.value.ourPackages ) : [])



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "p-2 w-72 h-24 bg-white uppercase text-sm flex justify-center items-center" }, "criteria", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(benchmarkCriteria.value, (criteria) => {
        return (_openBlock(), _createElementBlock("div", {
          key: criteria.criteria,
          class: "flex flex-col p-2 w-72 h-14 border-r bg-white justify-center border-t"
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(criteria.criteria), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(criteria.category), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "w-54 h-24 uppercase text-sm flex justify-center items-center" }, "Target Values", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(targetValuesForBenchmark.value.values, (target, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex justify-center items-center h-14"
          }, [
            (target.criteria.category == 'Commercial Value' && _ctx.editOn)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  "onUpdate:modelValue": ($event: any) => ((target.value) = $event),
                  class: "h-full w-full border-0 text-center"
                }, null, 8, _hoisted_7)), [
                  [_vModelText, target.value]
                ])
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (target.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(target.value), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_9, "-"))
                ], 64))
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(externalPackages.value, (extPack) => {
        return (_openBlock(), _createElementBlock("div", {
          key: extPack.name,
          class: "flex flex-col divide-y divide-slate-200 w-64"
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, _toDisplayString(extPack.name), 1),
            _createElementVNode("p", _hoisted_13, _toDisplayString(extPack.description), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extPack.values, (value) => {
            return (_openBlock(), _createBlock(BenchmarkTableCel, {
              key: value.criteria.criteria,
              "cel-value": value,
              "on-edit": _ctx.editOn,
              onColorChange: (colour) => value.colour = colour,
              onValueChange: (newValue) => value.value = newValue
            }, null, 8, ["cel-value", "on-edit", "onColorChange", "onValueChange"]))
          }), 128))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_14, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(internalPackages.value, (intPack) => {
        return (_openBlock(), _createElementBlock("div", {
          key: intPack.name,
          class: "flex flex-col divide-y divide-slate-200 w-64"
        }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", _hoisted_16, _toDisplayString(intPack.name), 1),
            _createElementVNode("p", _hoisted_17, _toDisplayString(intPack.description), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intPack.values, (value) => {
            return (_openBlock(), _createBlock(BenchmarkTableCel, {
              key: value.criteria.criteria,
              "cel-value": value,
              "on-edit": _ctx.editOn,
              onValueChange: (newValue) => value.value = newValue,
              onColorChange: (colour) => value.colour = colour
            }, null, 8, ["cel-value", "on-edit", "onValueChange", "onColorChange"]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
}

})